import { BankAccount } from "./bank-account.model";
import { Person } from "./person.model";
import { Producer } from "./producer.model";
import { User } from "./user.model";

export class Customer implements Person {
  id: string;
  name: string;
  phoneNumber?: string | undefined;
  addresses?: CustomerAddress[];
  isUser: boolean;
  mail?: string;
  user?: User;
  accountId?: string;
  bankAccounts?: BankAccount[];
  producers?: Producer[];

  constructor(
    id: string,
    name: string,
    phoneNumber: string | undefined,
    addresses: CustomerAddress[] | undefined,
    isUser: boolean | undefined,
    mail: string | undefined,
    user: User | undefined,
    accountId: string | undefined,
    bankAccounts: BankAccount[] | undefined,
    producers: Producer[] | undefined
    ) {
    this.id = id;
    this.name = name;
    this.phoneNumber = phoneNumber;
    this.addresses = addresses;
    this.isUser = isUser ?? false;
    this.mail = mail;
    this.user = user;
    this.accountId = accountId;
    this.bankAccounts = bankAccounts;
    this.producers = producers;
  }

  public get mainAddress(): CustomerAddress | undefined {
    if (!this.addresses || this.addresses.length < 1) {
      return undefined;
    }

    let ma = this.addresses.find(a => a.isMain) ?? this.addresses[0];
    return ma;
  }

  public get addressCount(): number {
    return this.addresses?.length ?? 0;
  }

  public allAdresses(): string {
    if (!this.addresses || this.addresses.length < 1) {
      return '';
    }

    return this.addresses.map(a => a.address).join(', \n');
  }

}

export interface CustomerAddress {
  address?: string;
  addressLine2?: string;
  latitude?: number;
  longitude?: number;
  note?: string;
  isMain?: boolean;
  officeId?: string;
}
